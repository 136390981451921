.details {
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.detail {
  margin-bottom: 10px;
}

.detail strong {
  color: #11a;
}
