.referral-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.referral-list-header {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

.referral-list-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.referral-list-table {
  flex: 1;
}
